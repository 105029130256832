import TypeIt from 'typeit';
import 'particles.js';
import particleConfig from './particlesjs-config.json';

import imagesLoaded from 'imagesloaded';

document.addEventListener('DOMContentLoaded', () => {
    const typeit = new TypeIt('#typeit', {
        html: true,
        breakLines: false,
        speed: 30
    })
    .type("Hi, I\'m <strong class='color-main'>Kristofers Ozoliņš</strong><br>")
    .pause(600)
    .type("I love making cool web based <i>things</i><br>").pause(600)
    .type("Currently work at <a target='_blank' referrerpolicy='no-referrer' href='https://magebit.com'>Magebit</a>");
    
    imagesLoaded(document.body, () => {
        document.querySelector('header').classList.remove('hidden');
        typeit.go();
    
        setTimeout(() => {
            document.querySelector('.skills').classList.remove('hidden');
        }, 500);
        setTimeout(() => {
            document.querySelector('.projects').classList.remove('hidden');
        }, 1000);
        setTimeout(() => {
            document.querySelector('.contact').classList.remove('hidden');
        }, 1500);
    });
    
    particlesJS('particles', particleConfig);
}, false);
